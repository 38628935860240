@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

:root {
    --primary: #d6daf5;
    --secondary: #323232;
    --primbuttn: #505baf;
    --secbuttn: #38473c;
}

body {
    background: black;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    color: var(--primary);
}

.image1 {
    position: fixed;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    z-index: -2;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}

.login {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 800px;
    height: 100vh;
}

.big-1 {
    font-family: 'Varela Round', sans-serif;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

h1 {
    background: linear-gradient(190deg, var(--primary), #714b6f);
    background-clip: text;
    display: flex;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
}

.form {
    flex-grow: 1;
    flex-direction: row;
}

button, button svg {
    color: var(--primary);
}

input {
    height: 32px;
    width: 400px;
    font-size: 14px;
    border-radius: 20px;
    text-align: center;
    padding: 3px;
    border: none;
}

p {
    text-align: right;
    font-size: 15px;
}

p:hover {
    text-decoration: underline;
    cursor: pointer;
}

label {
    margin: 25px;
    text-align: left;
}

.icon {
    margin-left: 25px;
}

.icon:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media only screen and (max-width: 1030px) {
    .form {
        flex-direction: column;
    }
    .icon {
        margin: 45px 0;
    }
}

@media only screen and (max-width: 500px) {
    input {
        width: 300px;
    }
}