@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap);
:root {
    --primary: #d6daf5;
    --secondary: #323232;
    --primbuttn: #505baf;
    --secbuttn: #38473c;
}

body {
    background: black;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    color: #d6daf5;
    color: var(--primary);
}

.image1 {
    position: fixed;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    z-index: -2;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}

.login {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 800px;
    height: 100vh;
}

.big-1 {
    font-family: 'Varela Round', sans-serif;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

h1 {
    background: linear-gradient(190deg, #d6daf5, #714b6f);
    background: linear-gradient(190deg, var(--primary), #714b6f);
    background-clip: text;
    display: flex;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
}

.form {
    flex-grow: 1;
    flex-direction: row;
}

button, button svg {
    color: #d6daf5;
    color: var(--primary);
}

input {
    height: 32px;
    width: 400px;
    font-size: 14px;
    border-radius: 20px;
    text-align: center;
    padding: 3px;
    border: none;
}

p {
    text-align: right;
    font-size: 15px;
}

p:hover {
    text-decoration: underline;
    cursor: pointer;
}

label {
    margin: 25px;
    text-align: left;
}

.icon {
    margin-left: 25px;
}

.icon:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media only screen and (max-width: 1030px) {
    .form {
        flex-direction: column;
    }
    .icon {
        margin: 45px 0;
    }
}

@media only screen and (max-width: 500px) {
    input {
        width: 300px;
    }
}
* {
    scrollbar-width: thin;
    scrollbar-color: inherit #402f6c;
}

*::-webkit-scrollbar {
    width: 9px;
}

*::-webkit-scrollbar-track {
    background: inherit;
}

*::-webkit-scrollbar-thumb {
    background-color: #402f6c;
    border-radius: 10px;
}

.image2 {
    position: fixed;
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px);
    object-fit: cover;
    width: 100%;
    height: 100vh;
    z-index: -1;
}

.mainChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.room {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    width: 90%;
    max-width: 1920px;
    grid-gap: 20px;
    gap: 20px;
    flex-grow: 1;
}

.send ::-webkit-input-placeholder {
    color: var(--primary);
}

.send ::placeholder {
    color: var(--primary);
}

.chat {
    flex-direction: row;
    margin: 50px 0 20px;
    flex-grow: 0;
}

.users {
    display: flex;
    font-size: 20px;
    margin: 20px 20px 0;
    width: 90%;
}

.typing {
    display: flex;
    width: 90%;
}

.navbar {
    font-size: 30px;
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
}

.currUser {
    display: flex;
    align-items: center;
}

button {
    font-size: 15px;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

button:hover {
    opacity: 0.6;
}

.send {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.send input {
    background: inherit;
    color: var(--primary);
    border-radius: 3px;
    border: 1px solid var(--primary);
    width: 95%;
}

.messages {
    margin: 15px 0;
    width: 90%;
    height: 60vh;
}

.message {
    border: 1px solid var(--primary);
    border-radius: 30px;
    align-self: flex-start;
    padding: 10px;
    margin: 5px;
}

.messages button {
    background: var(--primbuttn);
}

.messages button:hover {
    background: var(--secbuttn);
}

.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.my-message {
    align-self: flex-end;
}

.my-message h5 {
    text-align: end;
}

.room .messages > div {
    display: flex;
    flex-direction: column;
    width: auto !important;
    overflow: auto;
    height: inherit;
    padding: 1rem;
}

@media only screen and (max-width: 450px) {
    .navbar {
        font-size: 20px;
    }
    .users, .message h3 {
        font-size: 17px;
    }
    .chat {
        font-size: 30px;
    }
}
.broken {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
}
